import { CastSharp } from '@mui/icons-material';
import { Box, CardContent, Modal, Skeleton, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ICameraModel } from '../../models/Models';
import { appService } from '../../services/AppService';
import { GetAndroidPadding, UseWindowDimensions, useInterval } from '../../services/Utils';
import * as Grey from "../StyledComponents";

interface Props {
    data: ICameraModel,
}

let count = 0;
export function CameraPanel(props: Props) {
    const theme = useTheme();
    const [url, setUrl] = useState(props.data.url);
    const navigate = useNavigate();
    const size = UseWindowDimensions();

    const [open, setOpen] = useState(false);
    const handleOpen = () => {
        setOpen(true);
    }
    const handleClose = () => setOpen(false);

    const cast = async (id: string) => {
        await appService.castCamera(id);
    }

    useInterval(async () => {
        if (!window.location.pathname.endsWith('/cameras')) {
            return;
        }
        try {
            if (count >= 1000) {
                count = 0;
            }
            setUrl(props.data.url + '?t=' + count++);
        } catch { }
    }, 3000);

    return (<Grey.AutomationCard className={`card card-${theme.name}`} variant="outlined" sx={{ maxWidth: size.width > 700 ? (size.width / (2.5)) : 350 }}>
        <CardContent sx={{ position: 'relative' }}>
            <Grey.AutomationTitle>
                {props.data.title}
            </Grey.AutomationTitle>
            <Grey.CustomButton onClick={handleOpen} sx={{ padding: 0, marginX: '-15px' }}>
                <div style={{ textAlign: "left" }}>
                    <img src={url} style={{ width: '100%' }} />
                </div>
            </Grey.CustomButton>
            {props.data.hasFiles ?
                <Grey.ThemedChip
                    sx={{ float: 'left', marginTop: '5px', marginBottom: '15px', marginRight: '15px' }}
                    onClick={() => navigate(props.data.id)} label='Files' /> : null}
            {props.data.canCast ? <Grey.ThemedChip sx={{ float: 'left', marginTop: '5px', marginBottom: '15px', paddingLeft: '5px' }} onClick={() => cast(props.data.id)}
                label='Cast' icon={<CastSharp />} /> : null}
            <Modal
                sx={{ right: GetAndroidPadding() }}
                open={open}
                onClose={handleClose}>
                <Box sx={{
                    position: 'absolute' as 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    border: '2px solid #000',
                    boxShadow: 24,
                    p: 0,
                }}>
                    <Grey.CustomButton style={{ textAlign: "left", padding: 0, margin: 0 }} onClick={handleClose}>
                        <img src={url} style={{ maxWidth: size.width > 700 ? size.width - 200 : size.width - 40, maxHeight: size.height - 100 }} />
                    </Grey.CustomButton>
                </Box>
            </Modal>
        </CardContent>
    </Grey.AutomationCard>
    );
}

export function CamerasPage() {
    const [cameras, setCameras] = useState<ICameraModel[] | null>(null);
    const navigate = useNavigate();

    useEffect(() => {
        async function load() {
            try {
                setCameras(await appService.getCameras());
            } catch (error) {
                navigate('error')
            }
        }

        load();
    }, []);

    const skele = <React.Fragment>
        <Grey.AutomationCard sx={{ marginRight: '15px' }}>
            <Grey.AutomationTitle>
                <Skeleton variant='text' sx={{ marginTop: '10px', marginLeft: '15px' }} height={35} width={150} />
            </Grey.AutomationTitle>
            <CardContent>
                <Skeleton variant='rectangular' width={370} height={250} sx={{ marginRight: '15px' }} />
            </CardContent>
        </Grey.AutomationCard>
    </React.Fragment>;
    return (<div className='grid' style={{ marginLeft: '10px' }}>
        {cameras ? cameras?.map(camera =>
            <CameraPanel key={camera.id} data={camera} />
        ) : <React.Fragment>
            {skele}{skele}
        </React.Fragment>
        }
    </div>
    )
}
